import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="Writ of Restitution" mdxType="VizPage">
        <br></br>
        <iframe src="https://public.tableau.com/views/WritofRestitution/WritofRestitution?:showVizHome=no&:embed=true" allowFullScreen="true" width="1015" height="835"></iframe>
        <br></br>
        <h2>{`About`}</h2>
        <br></br>
        <p>{`Data can be filtered by writ's issued during winter months.`}</p>
        <br></br>
        <dt> <strong> Eviction Filing: </strong> represents a court action typically filed by a landlord or property manager to begin eviction proceedings.</dt>
        <dt> <strong> Writ of Restitution: </strong> an order by the court to return possession of the rental property to the landlord. </dt>
        <br></br>
        <p><strong parentName="p">{`Eviction Records`}</strong></p>
        <br></br>
        <p>{`Eviction data is pulled from the WCCA REST Interface — programmatic access to Wisconsin Circuit Court records. Small claims cases in Milwaukee County with a case type of small claims eviction are pulled down and stored on a weekly basis. CCAP provides no warranties as to the accuracy or timeliness of the information contained in the WCCA Data.`}</p>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      